<template>
    <div class="px-[27px] pb-[20px]">
        <div v-if="savedFlag" class="fixed top-[100px] right-[50px] text-center py-[10px] bg-[#8FC408] w-[200px] rounded-md text-[#000]">
            Збережено
        </div>
        <Header 
            :fcName="clubNameUkr"
            :fcAddress="legalAddress"
            :firstPhone="clubPhone"
            :secondPhone="clubAdditionalPhone"

            :webPage="webPage"
            :facebookPage="facebookPage"
            :instagramPage="instagramPage"

            :bankName="bankName"
            :bankAccount="receiverAccount"
            :receiverId="receiverCode"
            :ERDPOUcode="EDRPOUcode"

            :sinceDate="dateOfFoundationText"
        />

        <GeneralInformation 
            v-model:clubNameUkr="clubNameUkr"
            v-model:clubNameEng="clubNameEng"
            
            :dateOfFoundationText="dateOfFoundationText"
            @update:dateOfFoundationText="changeDateOfFoundation($event)"

            v-model:legalAddress="legalAddress"
            v-model:postAddress="postAddress"
            v-model:linkToMap="linkToMap"
        />
        
        <Contacts 
            v-model:clubPhone="clubPhone"
            v-model:clubAdditionalPhone="clubAdditionalPhone"
            v-model:clubEmail="clubEmail"
            v-model:facebookPage="facebookPage"
            v-model:webPage="webPage"
            v-model:instagramPage="instagramPage"
        />

        <BankDetails 
            v-model:bankName="bankName"
            v-model:EDRPOUcode="EDRPOUcode"
            v-model:receiverCode="receiverCode"
            v-model:receiverAccount="receiverAccount"
            v-model:wayforpayKey="wayforpayKey"
        />
        <!-- <Documents /> -->

        <div class="mt-[20px] font-bold">
            Налаштування суб'єктивних показників 
        </div>

        <div class="flex justify-center">
            <div class="w-max mt-[20px]">
                <div class="text-center">
                    Воротар
                </div>
                <div class="mt-[20px] flex gap-[20px]">
                    <div>
                        <InputComponent :key="radarKey" :value="newIndicatorName" @update:modelValue = "e => newIndicatorName = e" :placeholder="'Новий показник'" />

                        <div v-for="(indicator,id) in playerIndicators" class="flex items-center">
                            <InputComponent 
                                class="mt-[10px]"  
                                :value="playerIndicators[id]" 
                                @update:modelValue="e => { playerIndicators[id] = e; indocatorsValues[id] = e; indicatorsObjects[id].name = e; radarKey = new Date().getTime(); }"    
                            />
                            <div>
                                <button 
                                    class="rounded-[10px] text-sm font-medium text-red-600"
                                    @click="
                                        (e) => {
                                            playerIndicators.splice(id, 1);
                                            indicatorsObjects.splice(id, 1);
                                            indocatorsValues.splice(id, 1);
                                            radarKey = new Date().getTime();
                                        }
                                    "
                                >
                                    <img src="@/assets/icons/trash_icon.svg" class="w-6 h-6 relative ml-[10px] top-[7px]">
                                </button>
                            </div>
                        </div>
                    </div>
                    <div>
                        <RadarChart :key="radarKey" :chartData="indocatorsValues" :indicators="indicatorsObjects" />
                    </div>
                </div>
            </div>
            <div class="w-max mt-[20px]">
                <div class="text-center">
                    Польовий гравець
                </div>
                <div class="mt-[20px] flex gap-[20px]">
                    <div>
                        <InputComponent :key="radarKey2" :value="newIndicatorName2" @update:modelValue = "e => newIndicatorName2 = e" :placeholder="'Новий показник'" />
                        
                        <div v-for="(indicator,id) in playerIndicators2" class="flex items-center">
                            <InputComponent 
                                class="mt-[10px]"  
                                :value="playerIndicators2[id]" 
                                @update:modelValue="e => { playerIndicators2[id] = e; indocatorsValues2[id] = e; indicatorsObjects2[id].name = e; radarKey2 = new Date().getTime(); }"    
                            />
                            <div>
                                <button 
                                    class="rounded-[10px] text-sm font-medium text-red-600"
                                    @click="
                                        (e) => {
                                            playerIndicators2.splice(id, 1);
                                            indicatorsObjects2.splice(id, 1);
                                            indocatorsValues2.splice(id, 1);
                                            radarKey2 = new Date().getTime();
                                        }
                                    "
                                >
                                    <img src="@/assets/icons/trash_icon.svg" class="w-6 h-6 relative ml-[10px] top-[7px]">
                                </button>
                            </div>
                        </div>
                    </div>
                    <div>
                        <RadarChart :key="radarKey2" :chartData="indocatorsValues2" :indicators="indicatorsObjects2" />
                    </div>
                </div>
            </div>
        </div>

        <div class="mt-[20px] font-bold">
            Налаштування об'єктивних показників
        </div>

        <div class="flex justify-center">
            <!-- <div class="w-max mt-[20px]">
                <div class="text-center">
                    Воротар
                </div>
                <div class="mt-[20px] flex gap-[20px]">
                    <div>
                        <InputComponent :key="radarKeyObjective" :value="newIndicatorNameObjective" @update:modelValue = "e => newIndicatorNameObjective = e" :placeholder="'Новий показник'" />

                        <div v-for="(indicator,id) in playerIndicatorsObjective" class="flex items-center">
                            <InputComponent 
                                class="mt-[10px]"  
                                :value="playerIndicatorsObjective[id]" 
                                @update:modelValue="e => { playerIndicatorsObjective[id] = e; indocatorsValuesObjective[id] = e; indicatorsObjectsObjective[id].name = e; radarKeyObjective = new Date().getTime(); }"   
                            />
                            <div>
                                <button 
                                    class="rounded-[10px] text-sm font-medium text-red-600"
                                    @click="
                                        (e) => {
                                            playerIndicatorsObjective.splice(id, 1);
                                            indicatorsObjectsObjective.splice(id, 1);
                                            indocatorsValuesObjective.splice(id, 1);
                                            radarKeyObjective = new Date().getTime();
                                        }
                                    "
                                >
                                    <img src="@/assets/icons/trash_icon.svg" class="w-6 h-6 relative ml-[10px] top-[7px]">
                                </button>
                            </div>
                        </div>
                    </div>
                    <div>
                        <RadarChart :key="radarKeyObjective" :chartData="indocatorsValuesObjective" :indicators="indicatorsObjectsObjective" />
                    </div>
                </div>
            </div> -->
            <div class="w-max mt-[20px]">
                <div class="text-center">
                    Польовий гравець
                </div>
                <div class="mt-[20px] flex gap-[20px]">
                    <div>
                        <InputComponent :key="radarKeyObjective2" :value="newIndicatorNameObjective2" @update:modelValue = "e => newIndicatorNameObjective2 = e" :placeholder="'Новий показник'" />
                        
                        <div v-for="(indicator,id) in playerIndicatorsObjective2" class="flex items-center">
                            <InputComponent 
                                class="mt-[10px]"  
                                :value="playerIndicatorsObjective2[id]" 
                                @update:modelValue="e => { playerIndicatorsObjective2[id] = e; indocatorsValuesObjective2[id] = e; indicatorsObjectsObjective2[id].name = e; radarKeyObjective2 = new Date().getTime(); }"    
                            />
                            <div>
                                <button 
                                    class="rounded-[10px] text-sm font-medium text-red-600"
                                    @click="
                                        (e) => {
                                            playerIndicatorsObjective2.splice(id, 1);
                                            indicatorsObjectsObjective2.splice(id, 1);
                                            indocatorsValuesObjective2.splice(id, 1);
                                            radarKeyObjective2 = new Date().getTime();
                                        }
                                    "
                                >
                                    <img src="@/assets/icons/trash_icon.svg" class="w-6 h-6 relative ml-[10px] top-[7px]">
                                </button>
                            </div>
                        </div>
                    </div>
                    <div>
                        <RadarChart :key="radarKeyObjective2" :chartData="indocatorsValuesObjective2" :indicators="indicatorsObjectsObjective2" />
                    </div>
                </div>
            </div>
        </div>

        <div class="mt-[30px] flex justify-center">
            <button @click="updateSettings" class="w-[200px] h-[40px] rounded-[6px] bg-[#8FC408] text-[#000] font-medium text-[14px]">
                Зберегти
            </button>
        </div>
    </div>
</template>

<script>
    import { ref, onMounted, watch } from 'vue'
    import { getAllSettings, updateSettingsRequest, updateScoreSettings, getScoreSettings, updateObjectiveScoreSettings, getObjectiveScoreSettings } from '@/services/apiRequests' 
    import { uuid } from 'vue3-uuid'

    import BankDetails from "./components/BankDetails.vue"
    import Contacts from "./components/Contacts.vue"
    import Documents from "./components/Documents.vue"
    import GeneralInformation from "./components/GeneralInformation.vue"
    import Header from "./components/Header.vue"

    export default {
        name: 'settings',

        setup() {
            const clubNameUkr = ref('')
            const clubNameEng = ref('')

            const dateOfFoundationText = ref('')
            const dateOfFoundationDate = ref(new Date())

            const legalAddress = ref('')
            const postAddress = ref('')

            const linkToMap = ref('')

            const clubPhone = ref('')
            const clubAdditionalPhone = ref('')
            const clubEmail = ref('')
            const facebookPage = ref('')
            const webPage = ref('')
            const instagramPage = ref('')

            const bankName = ref('')
            const EDRPOUcode = ref('')
            const receiverCode = ref('')

            const receiverAccount = ref('')
            const wayforpayKey = ref('')

            const savedFlag = ref(false)

            const updateSettings = async () => {
                const settings = {
                    club_name_ukr: clubNameUkr.value,
                    club_name_eng: clubNameEng.value,
                    legal_address: legalAddress.value,
                    post_address: postAddress.value,
                    link_to_map: linkToMap.value,
                    club_phone: clubPhone.value,
                    club_additional_phone: clubAdditionalPhone.value,
                    club_email: clubEmail.value,
                    facebook_page: facebookPage.value,
                    web_page: webPage.value,
                    instagram_page: instagramPage.value,
                    bank_name: bankName.value,
                    EDRPOU_code: EDRPOUcode.value,
                    receiver_code: receiverCode.value,
                    receiver_account: receiverAccount.value,
                    wayforpay_key: wayforpayKey.value,
                    date_of_foundation: dateOfFoundationText.value
                }

                const settingsRes = await updateSettingsRequest(settings)

                const goalkeeperSettings = {
                    type: "score_settings",
                    player_type: "goalkeeper",
                    first_point_name: playerIndicators.value[0],
                    second_point_name: playerIndicators.value[1],
                    third_point_name: playerIndicators.value[2],
                    fourth_point_name: playerIndicators.value[3],
                    fifth_point_name: playerIndicators.value[4],
                    sixth_point_name: playerIndicators.value[5],
                    seventh_point_name: playerIndicators.value[6],
                    eighth_point_name: playerIndicators.value[7],
                    ninth_point_name: playerIndicators.value[8]
                }

                const fieldPlayerSettings = {
                    type: "score_settings",
                    player_type: "field_player",
                    first_point_name: playerIndicators2.value[0],
                    second_point_name: playerIndicators2.value[1],
                    third_point_name: playerIndicators2.value[2],
                    fourth_point_name: playerIndicators2.value[3],
                    fifth_point_name: playerIndicators2.value[4],
                    sixth_point_name: playerIndicators2.value[5],
                    seventh_point_name: playerIndicators2.value[6],
                    eighth_point_name: playerIndicators2.value[7],
                    ninth_point_name: playerIndicators2.value[8]
                }

                await updateScoreSettings(goalkeeperSettings, fieldPlayerSettings)

                const goalkeeperObjectiveSettings = {
                    type: "objective_score_settings",
                    player_type: "goalkeeper",
                    first_point_name: playerIndicatorsObjective.value[0],
                    second_point_name: playerIndicatorsObjective.value[1],
                    third_point_name: playerIndicatorsObjective.value[2],
                    fourth_point_name: playerIndicatorsObjective.value[3],
                    fifth_point_name: playerIndicatorsObjective.value[4],
                    sixth_point_name: playerIndicatorsObjective.value[5],
                    seventh_point_name: playerIndicatorsObjective.value[6],
                    eighth_point_name: playerIndicatorsObjective.value[7],
                    ninth_point_name: playerIndicatorsObjective.value[8]
                }

                const fieldPlayerObjectiveSettings = {
                    type: "objective_score_settings",
                    player_type: "field_player",
                    first_point_name: playerIndicatorsObjective2.value[0],
                    second_point_name: playerIndicatorsObjective2.value[1],
                    third_point_name: playerIndicatorsObjective2.value[2],
                    fourth_point_name: playerIndicatorsObjective2.value[3],
                    fifth_point_name: playerIndicatorsObjective2.value[4],
                    sixth_point_name: playerIndicatorsObjective2.value[5],
                    seventh_point_name: playerIndicatorsObjective2.value[6],
                    eighth_point_name: playerIndicatorsObjective2.value[7],
                    ninth_point_name: playerIndicatorsObjective2.value[8]
                }

                await updateObjectiveScoreSettings(goalkeeperObjectiveSettings, fieldPlayerObjectiveSettings)

                savedFlag.value = true

                setTimeout(() => {
                    savedFlag.value = false
                }, 2000)
            }

            onMounted(async () => {
                const settingsRes = await getAllSettings()

                if (settingsRes) {
                    clubNameUkr.value = settingsRes.club_name_ukr
                    clubNameEng.value = settingsRes.club_name_eng
                    legalAddress.value = settingsRes.legal_address
                    postAddress.value = settingsRes.post_address
                    linkToMap.value = settingsRes.link_to_map
                    clubPhone.value = settingsRes.club_phone
                    clubAdditionalPhone.value = settingsRes.club_additional_phone
                    clubEmail.value = settingsRes.club_email
                    facebookPage.value = settingsRes.facebook_page
                    webPage.value = settingsRes.web_page
                    instagramPage.value = settingsRes.instagram_page
                    bankName.value = settingsRes.bank_name
                    EDRPOUcode.value = settingsRes.EDRPOU_code
                    receiverCode.value = settingsRes.receiver_code
                    receiverAccount.value = settingsRes.receiver_account
                    wayforpayKey.value = settingsRes.wayforpay_key
                    dateOfFoundationText.value = settingsRes.date_of_foundation
                }

                const scoreSettingsRes = await getScoreSettings()

                if (scoreSettingsRes) {
                    console.log('scoreSettingsRes ------ ', scoreSettingsRes)
                    const goalkeeperScorePointNames = [
                        scoreSettingsRes.goalkeeper?.first_point_name,
                        scoreSettingsRes.goalkeeper?.second_point_name,
                        scoreSettingsRes.goalkeeper?.third_point_name,
                        scoreSettingsRes.goalkeeper?.fourth_point_name,
                        scoreSettingsRes.goalkeeper?.fifth_point_name,
                        scoreSettingsRes.goalkeeper?.sixth_point_name,
                        scoreSettingsRes.goalkeeper?.seventh_point_name,
                        scoreSettingsRes.goalkeeper?.eighth_point_name,
                        scoreSettingsRes.goalkeeper?.ninth_point_name
                    ]
                    playerIndicators.value = goalkeeperScorePointNames.filter(e => e != null)

                    indicatorsObjects.value = playerIndicators.value.map(e => ({ name: e, max: 10 }))

                    if (playerIndicators.value.length == 0) {
                        playerIndicators.value = ['Додайте власний показник']
                        indicatorsObjects.value = [{ name: 'Показник 1', max: 0 }]
                    }

                    const fieldPlayerScorePointNames = [
                        scoreSettingsRes.field_player?.first_point_name,
                        scoreSettingsRes.field_player?.second_point_name,
                        scoreSettingsRes.field_player?.third_point_name,
                        scoreSettingsRes.field_player?.fourth_point_name,
                        scoreSettingsRes.field_player?.fifth_point_name,
                        scoreSettingsRes.field_player?.sixth_point_name,
                        scoreSettingsRes.field_player?.seventh_point_name,
                        scoreSettingsRes.field_player?.eighth_point_name,
                        scoreSettingsRes.field_player?.ninth_point_name
                    ]
                    playerIndicators2.value = fieldPlayerScorePointNames.filter(e => e != null)

                    indicatorsObjects2.value = playerIndicators2.value.map(e => ({ name: e, max: 10 }))

                    if (playerIndicators2.value.length == 0) {
                        playerIndicators2.value = ['Додайте власний показник']
                        indicatorsObjects2.value = [{ name: 'Показник 1', max: 0 }]
                    }

                    radarKey.value = uuid.v4()
                    radarKey2.value = uuid.v4()
                }

                const objectiveScoreSettingsRes = await getObjectiveScoreSettings()

                if (objectiveScoreSettingsRes) {
                    console.log('objectiveScoreSettingsRes ------ ', objectiveScoreSettingsRes)
                    const goalkeeperScorePointNames = [
                        objectiveScoreSettingsRes.goalkeeper?.first_point_name,
                        objectiveScoreSettingsRes.goalkeeper?.second_point_name,
                        objectiveScoreSettingsRes.goalkeeper?.third_point_name,
                        objectiveScoreSettingsRes.goalkeeper?.fourth_point_name,
                        objectiveScoreSettingsRes.goalkeeper?.fifth_point_name,
                        objectiveScoreSettingsRes.goalkeeper?.sixth_point_name,
                        objectiveScoreSettingsRes.goalkeeper?.seventh_point_name,
                        objectiveScoreSettingsRes.goalkeeper?.eighth_point_name,
                        objectiveScoreSettingsRes.goalkeeper?.ninth_point_name
                    ]
                    playerIndicatorsObjective.value = goalkeeperScorePointNames.filter(e => e != null)

                    indicatorsObjectsObjective.value = playerIndicatorsObjective.value.map(e => ({ name: e, max: 10 }))

                    if (playerIndicatorsObjective.value.length == 0) {
                        playerIndicatorsObjective.value = ['Додайте власний показник']
                        indicatorsObjectsObjective.value = [{ name: 'Показник 1', max: 0 }]
                    }

                    const fieldPlayerScorePointNames = [
                        objectiveScoreSettingsRes.field_player?.first_point_name,
                        objectiveScoreSettingsRes.field_player?.second_point_name,
                        objectiveScoreSettingsRes.field_player?.third_point_name,
                        objectiveScoreSettingsRes.field_player?.fourth_point_name,
                        objectiveScoreSettingsRes.field_player?.fifth_point_name,
                        objectiveScoreSettingsRes.field_player?.sixth_point_name,
                        objectiveScoreSettingsRes.field_player?.seventh_point_name,
                        objectiveScoreSettingsRes.field_player?.eighth_point_name,
                        objectiveScoreSettingsRes.field_player?.ninth_point_name
                    ]
                    playerIndicatorsObjective2.value = fieldPlayerScorePointNames.filter(e => e != null)

                    indicatorsObjectsObjective2.value = playerIndicatorsObjective2.value.map(e => ({ name: e, max: 10 }))

                    if (playerIndicatorsObjective2.value.length == 0) {
                        playerIndicatorsObjective2.value = ['Додайте власний показник']
                        indicatorsObjectsObjective2.value = [{ name: 'Показник 1', max: 0 }]
                    }

                    radarKeyObjective.value = uuid.v4()
                    radarKeyObjective2.value = uuid.v4()
                }

                console.log('settingsRes ------ ', settingsRes)
            })

            const changeDateOfFoundation = (date) => {
                dateOfFoundationText.value = date
                dateOfFoundationDate.value = new Date(date)
            }

            const playerIndicators = ref(['Додайте власний показник'])
            const indicatorsObjects = ref([
                { name: 'Показник 1', max: 0 },
            ])
            const indocatorsValues = ref([0,0])
            const newIndicatorName = ref('')
            const radarKey = ref(uuid.v4())

            watch(newIndicatorName, (newValue) => {
                if(!newValue) return
                if (playerIndicators.value.length >= 9) {
                    return
                }

                if (playerIndicators.value[0] == 'Додайте власний показник') {
                    playerIndicators.value = []
                    indicatorsObjects.value = []
                    indocatorsValues.value = []
                }

                playerIndicators.value.push(newValue)
                indicatorsObjects.value.push({ name: newValue, max: 10 })
                radarKey.value = uuid.v4()
                newIndicatorName.value = ''
            })

            const playerIndicators2 = ref(['Додайте власний показник'])
            const indicatorsObjects2 = ref([
                { name: 'Показник 1', max: 0 },
            ])
            const indocatorsValues2 = ref([0,0])
            const newIndicatorName2 = ref('')
            const radarKey2 = ref(uuid.v4())

            watch(newIndicatorName2, (newValue) => {
                if(!newValue) return
                if (playerIndicators2.value.length >= 9) {
                    return
                }
                
                if (playerIndicators2.value[0] == 'Додайте власний показник') {
                    playerIndicators2.value = []
                    indicatorsObjects2.value = []
                    indocatorsValues2.value = []
                }

                playerIndicators2.value.push(newValue)
                indicatorsObjects2.value.push({ name: newValue, max: 10 })
                radarKey2.value = uuid.v4()
                newIndicatorName2.value = ''
            })

            // objective settings 

            const playerIndicatorsObjective = ref(['Додайте власний показник'])
            const indicatorsObjectsObjective = ref([
                { name: 'Показник 1', max: 0 },
            ])
            const indocatorsValuesObjective = ref([0,0])
            const newIndicatorNameObjective = ref('')
            const radarKeyObjective = ref(uuid.v4())

            watch(newIndicatorNameObjective, (newValue) => {
                if(!newValue) return
                if (playerIndicatorsObjective.value.length >= 9) {
                    return
                }

                if (playerIndicatorsObjective.value[0] == 'Додайте власний показник') {
                    playerIndicatorsObjective.value = []
                    indicatorsObjectsObjective.value = []
                    indocatorsValuesObjective.value = []
                }

                playerIndicatorsObjective.value.push(newValue)
                indicatorsObjectsObjective.value.push({ name: newValue, max: 10 })
                radarKeyObjective.value = uuid.v4()
                newIndicatorNameObjective.value = ''
            })

            const playerIndicatorsObjective2 = ref(['Додайте власний показник'])
            const indicatorsObjectsObjective2 = ref([
                { name: 'Показник 1', max: 0 },
            ])

            const indocatorsValuesObjective2 = ref([0,0])
            const newIndicatorNameObjective2 = ref('')
            const radarKeyObjective2 = ref(uuid.v4())
            
            watch(newIndicatorNameObjective2, (newValue) => {
                if(!newValue) return
                if (playerIndicatorsObjective2.value.length >= 9) {
                    return
                }
                
                if (playerIndicatorsObjective2.value[0] == 'Додайте власний показник') {
                    playerIndicatorsObjective2.value = []
                    indicatorsObjectsObjective2.value = []
                    indocatorsValuesObjective2.value = []
                }

                playerIndicatorsObjective2.value.push(newValue)
                indicatorsObjectsObjective2.value.push({ name: newValue, max: 10 })
                radarKeyObjective2.value = uuid.v4()
                newIndicatorNameObjective2.value = ''
            })


            return {
                clubNameUkr,
                clubNameEng,
                dateOfFoundationText,
                dateOfFoundationDate,
                legalAddress,
                postAddress,
                linkToMap,
                clubPhone,
                clubAdditionalPhone,
                clubEmail,
                facebookPage,
                webPage,
                instagramPage,
                bankName,
                EDRPOUcode,
                receiverCode,
                receiverAccount,
                wayforpayKey,
                changeDateOfFoundation,
                updateSettings,
                playerIndicators,
                newIndicatorName,
                indicatorsObjects,
                indocatorsValues,
                radarKey,
                savedFlag,

                playerIndicators2,
                newIndicatorName2,
                indicatorsObjects2,
                indocatorsValues2,
                radarKey2,

                playerIndicatorsObjective,
                newIndicatorNameObjective,
                indicatorsObjectsObjective,
                indocatorsValuesObjective,
                radarKeyObjective,

                playerIndicatorsObjective2,
                newIndicatorNameObjective2,
                indicatorsObjectsObjective2,
                indocatorsValuesObjective2,
                radarKeyObjective2
            }
        },

        components: {
            BankDetails,
            Contacts,
            Documents,
            GeneralInformation,
            Header
        },
    }
</script>

<style lang="scss" scoped>

</style>